/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable react/button-has-type */

import { gameBookTemplateActions } from '@orientaction/api-actions';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './TextEditor.css';

interface ITextEditor {
  isSmall?: boolean;
  handleActiveStyles?: (style: any) => void;
  handleFocused?: (focused: boolean) => void;
  styleEditor?: any;
  linkTitleE?: string;
  linkUrlE?: string;
  showLinkPopupE?: boolean;
  reinit?: boolean;
  field: string;
  defaultValue?: any;
}

const TextEditor: React.FC<ITextEditor> = ({
  isSmall = false,
  handleActiveStyles,
  handleFocused,
  styleEditor,
  linkTitleE,
  linkUrlE,
  showLinkPopupE,
  reinit,
  field,
  defaultValue = '',
}) => {
  const [content, setContent] = useState<string>('');
  const [activeStyles, setActiveStyles] = useState<Record<string, any>>({});
  const [showLinkPopup, setShowLinkPopup] = useState<boolean>(true);
  const [linkTitle, setLinkTitle] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [lineHeightState, setLineHeightState] = useState<string>('1.75');
  const editorRef = useRef<HTMLDivElement>(null);
  const savedSelection = useRef<Range | null>(null);
  const dispatch = useDispatch();

  const styleToggle = [
    'bold',
    'italic',
    'underline',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'insertUnorderedList',
    'insertOrderedList',
  ];

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (savedSelection.current && selection) {
      selection.removeAllRanges();
      selection.addRange(savedSelection.current);
    }
  };

  const applyStyle = (styleCommand: string, arg?: string) => {
    saveSelection();

    if (styleCommand === 'reset') {
      document.execCommand('removeFormat', false, '');
      document.execCommand('unlink', false, '');
      if (editorRef.current) {
        editorRef.current.style.lineHeight = '';
      }
      setActiveStyles({});
      return;
    }

    if (styleCommand === 'lineHeight') {
      if (editorRef.current) {
        restoreSelection();
        setLineHeightState(arg || '1.75');
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const selectedText = range?.toString();
        if (selectedText) {
          const span = document.createElement('span');
          span.style.lineHeight = arg || '';
          span.textContent = selectedText;
          document.execCommand('insertHTML', false, span.outerHTML);
          setActiveStyles((prev) => ({ ...prev, lineHeight: arg }));
        }
      }
    } else if (styleCommand.startsWith('justify')) {
      const isActive = activeStyles[styleCommand];
      document.execCommand(isActive ? 'justifyLeft' : styleCommand, false, '');
      setActiveStyles((prev) => ({
        ...prev,
        justifyLeft: false,
        justifyCenter: false,
        justifyRight: false,
        [styleCommand]: !isActive,
      }));
    } else if (['insertUnorderedList', 'insertOrderedList'].includes(styleCommand)) {
      const isActive = activeStyles[styleCommand];
      document.execCommand(isActive ? 'insertParagraph' : styleCommand, false, '');
      setActiveStyles((prev) => ({
        ...prev,
        insertUnorderedList: false,
        insertOrderedList: false,
        [styleCommand]: !isActive,
      }));
    } else if (styleCommand === 'formatBlock' && arg) {
      removeCurrentBlockFormat();
      document.execCommand(styleCommand, false, arg);
      setActiveStyles((prev) => ({ ...prev, formatBlock: arg }));
      const elements = editorRef.current?.getElementsByTagName(arg.toLowerCase()) as any;
      if (elements) {
        for (const element of Array.from(elements) as any) {
          element.style.fontWeight = 'normal';
        }
      }
    } else {
      document.execCommand(styleCommand, false, arg || '');
      updateActiveStyles();
    }

    restoreSelection();
  };

  const removeCurrentBlockFormat = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;
    let parentBlock = container.parentElement;
    while (parentBlock && !parentBlock.matches('div, p, h1, h2, h3, h4, h5, h6')) {
      parentBlock = parentBlock.parentElement;
    }
    if (parentBlock && parentBlock.matches('h1, h2, h3, h4, h5, h6')) {
      const fragment = document.createDocumentFragment();
      while (parentBlock.firstChild) {
        fragment.appendChild(parentBlock.firstChild);
      }
      parentBlock.parentNode?.replaceChild(fragment, parentBlock);
    }
  };

  const resetSelectedStyle = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);

    const contentx = range.extractContents() as any;
    const plainText = contentx.textContent || '';

    range.deleteContents();
    range.insertNode(document.createTextNode(plainText));

    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNodeContents(range.commonAncestorContainer);
    selection.addRange(newRange);

    document.execCommand('removeFormat', false, '');
    document.execCommand('unlink', false, '');
    if (editorRef.current) {
      editorRef.current.style.lineHeight = '';
    }
    setActiveStyles({});
    updateActiveStyles();
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  const getCurrentLineHeight = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return '1.75';
    const range = selection.getRangeAt(0);
    const { parentElement } = range.commonAncestorContainer;

    if (parentElement) {
      const computedStyle = window.getComputedStyle(parentElement);
      return computedStyle.lineHeight;
    }
    return '1.75';
  };

  const updateActiveStyles = () => {
    const styles: Record<string, boolean | string> = {
      bold: document.queryCommandState('bold'),
      italic: document.queryCommandState('italic'),
      underline: document.queryCommandState('underline'),
      justifyLeft: document.queryCommandState('justifyLeft'),
      justifyCenter: document.queryCommandState('justifyCenter'),
      justifyRight: document.queryCommandState('justifyRight'),
      insertOrderedList: document.queryCommandState('insertOrderedList'),
      insertUnorderedList: document.queryCommandState('insertUnorderedList'),
      link: document.queryCommandState('createLink'),
      formatBlock: document.queryCommandValue('formatBlock').toLowerCase(),
      lineHeight: getCurrentLineHeight(),
      isMultipleBlockFormats: checkMultipleBlockFormats(),
    };

    setActiveStyles(styles);
  };

  const handleContentChange = () => {
    if (editorRef.current) {
      setContent(editorRef.current.innerHTML);
      dispatch(gameBookTemplateActions.updateField(field, editorRef.current.innerHTML));
    }
    updateActiveStyles();
  };

  const handleInsertLink = (e: any) => {
    restoreSelection();
    e.stopPropagation();

    if (linkTitle && linkUrl) {
      const linkHTML = `<a href="${linkUrl}" target="_blank">${linkTitle}</a>`;
      document.execCommand('insertHTML', false, linkHTML);
    }
    if (handleFocused) handleFocused(true);
    setShowLinkPopup(false);
    setLinkTitle('');
    setLinkUrl('');
    handleContentChange();
    setActiveStyles((prev) => ({ ...prev, link: false }));
  };

  useEffect(() => {
    if (!editorRef.current) return;

    const handleBackspace = (event: any) => {
      if (event.key === 'Backspace' && editorRef.current?.innerText.trim().length === 0) {
        event.preventDefault();
        editorRef.current.innerHTML = '&#8203;';
      }
    };

    const handleEnter = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        document.execCommand('insertHTML', false, '<div><br/></div>');
      }
    };

    const handleSelectionChange = () => {
      updateActiveStyles();
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    editorRef.current.addEventListener('keydown', handleBackspace);
    editorRef.current.addEventListener('keydown', handleEnter);
    editorRef.current.addEventListener('input', handleContentChange);

    updateActiveStyles();

    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', handleBackspace);
        editorRef.current.removeEventListener('keydown', handleEnter);
      }
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    if (styleEditor) {
      if (styleEditor.styleCommand === 'reset') {
        resetSelectedStyle();
      } else if (styleToggle.includes(styleEditor.styleCommand)) {
        toggleStyle(styleEditor.styleCommand);
      } else {
        applyStyle(styleEditor.styleCommand, styleEditor.arg);
      }
    }
  }, [styleEditor]);

  useEffect(() => {
    if (showLinkPopupE !== undefined) {
      saveSelection();
      setShowLinkPopup(!showLinkPopup);
    }
  }, [showLinkPopupE]);

  useEffect(() => {
    if (handleActiveStyles) {
      handleActiveStyles(activeStyles);
    }
  }, [activeStyles]);

  useEffect(() => {
    if (editorRef.current && content === '') {
      editorRef.current.innerHTML = defaultValue;
      setContent(defaultValue);
      updateActiveStyles();
      saveSelection();
    }
  }, [defaultValue]);

  const toggleStyle = (styleCommand: string) => {
    const isActive = activeStyles[styleCommand];
    document.execCommand(styleCommand, false, !isActive.toString() as any);
    setActiveStyles((prev) => ({ ...prev, [styleCommand]: !isActive }));
  };
  const checkMultipleBlockFormats = () => {
    const selection: Selection | null = window.getSelection();
    if (!selection || selection.rangeCount === 0) return false;

    const range: Range = selection.getRangeAt(0);
    const blockFormats = new Set<string>();

    const getBlockFormat = (node: Node): string | null => {
      while (node && node.nodeType === Node.TEXT_NODE) {
        node = node.parentElement!;
      }
      if (node && node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (element.matches('div, p, h1, h2, h3, h4, h5, h6')) {
          return element.tagName.toLowerCase();
        }
      }
      return null;
    };

    const treeWalker = document.createTreeWalker(
      range.commonAncestorContainer,
      NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT,
      {
        acceptNode: (node) => {
          if (range.intersectsNode(node)) return NodeFilter.FILTER_ACCEPT;
          return NodeFilter.FILTER_REJECT;
        },
      },
    );

    let { currentNode } = treeWalker;

    while (currentNode) {
      const format = getBlockFormat(currentNode);
      if (format) {
        blockFormats.add(format);
      }
      currentNode = treeWalker.nextNode() as Node;
    }

    return blockFormats.size > 1;
  };

  return (
    <div className="editor-container">
      <div className="toolbar">
        {styleToggle.map((style) => (
          <button
            key={style}
            className={activeStyles[style] ? 'active' : ''}
            onClick={() => toggleStyle(style)}
          >
            {style.charAt(0).toUpperCase() + style.slice(1)}
          </button>
        ))}
        <select
          value={activeStyles.formatBlock}
          onChange={(e) => {
            const newFormatBlock = e.target.value;
            removeCurrentBlockFormat();
            applyStyle('formatBlock', newFormatBlock);
            if (lineHeightState) {
              applyStyle('lineHeight', lineHeightState);
            }
          }}
        >
          <option value="div">Paragraphe</option>
          <option value="h6">Très Petit</option>
          <option value="h5">Petit</option>
          <option value="h4">Moyen</option>
          <option value="h3">Grand</option>
          <option value="h2">Sous-titre</option>
          <option value="h1">Très grand</option>
        </select>
        <select value={lineHeightState} onChange={(e) => applyStyle('lineHeight', e.target.value)}>
          <option value="1.15">1</option>
          <option value="1.75">1.15</option>
          <option value="2">1.5</option>
          <option value="3">2</option>
        </select>
        <button onClick={resetSelectedStyle}>Réinitialiser le style sélectionné</button>
      </div>
      <div
        ref={editorRef}
        contentEditable={true}
        placeholder="Cliquer pour ajouter du texte"
        className="editable-area-not-choice editor"
        onInput={handleContentChange}
        style={{ minHeight: isSmall ? 156 : 334, lineHeight: lineHeightState }}
        onClick={(e) => {
          e.stopPropagation();
          if (handleFocused) handleFocused(true);
        }}
        onFocus={() => {
          if (handleFocused) handleFocused(true);
          if (handleActiveStyles) handleActiveStyles(activeStyles);
        }}
        onPaste={handlePaste}
      />
      {showLinkPopup && (
        <div
          className="link-popup"
          onClick={(e) => e.stopPropagation()}
          onFocus={() => handleFocused && handleFocused(true)}
        >
          <input
            type="text"
            value={linkTitle}
            onChange={(e) => setLinkTitle(e.target.value)}
            placeholder="Entrer le titre du lien"
          />
          <input
            type="text"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            placeholder="Entrer l'URL du lien"
          />
          <button onClick={handleInsertLink}>Insérer</button>
          <button
            onClick={() => {
              setShowLinkPopup(false);
              setLinkTitle('');
              setLinkUrl('');
            }}
          >
            Annuler
          </button>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
